import { WithId } from './typesUtils';

export type ProductionVisibility = 'Public' | 'Private';
export const PRODUCTION_VISIBILITIES = ['Public', 'Private'];

export type MainGoalType =
  | 'reveal_knowledge'
  | 'action'
  | 'share_internal_knowledge'
  | 'other';

export type SessionLanguage = 'fr' | 'en';

export type DurationValues = 60 | 90 | 120;

export type Session = {
  communityId?: string;
  title: string;
  scheduledAt: [number, number];
  accessCode: string;
  createdAt: number;
  previewSessionId?: string;
  usesMailAutomation?: boolean;
  location?: string;
  meetingUrl?: string;
  // used in admin for filtering
  kindsOfPublic?: Record<string, true>;
  // used in conception
  participantProfile?: string;
  enableStepsAutomatically?: boolean;
  isTemplate?: boolean;
  fromId?: string;
  groupPrefix?: string;
  description?: string;
  goals?: string[];
  successCriteria?: string[];
  language?: SessionLanguage;
  type?: SessionTypeType;
  hidden?: boolean;
  mainGoal?: MainGoalType;
  theme?: string;
  peerProfile?: string;
  postResultDescription?: string;
  duration?: DurationValues;
  internalVisio?: boolean;
};

export type Sessions = Record<string, Session>;

export type Community = {
  name: string;
  createdAt: number;
  nbMembers: number;
};
export type Communities = Record<string, Community>;

export type DocMeta = {
  id: string;
  abstract: string;
  authors?: Record<string, WithId<User>>;
  communityId: string;
  createdAt: number;
  current: string;
  lastRevisionAt: number;
  name: string;
  sessionId: string;
  activityId: string;
  groupId?: string;
  title: string;
  authorsIds?: Record<string, true>;
  coverUrl?: string;
  type: string;
  visibility?: ProductionVisibility;
};

export type DocWithMeta<T = unknown> = DocMeta & {
  content: T;
};

export type FakeDoc = {
  id: string;
  name: string;
  content: any;
  fake: true;
};
export type FakeDocs = Record<string, FakeDoc>;

export type DocSearchResults = {
  page: number;
  nbPages: number;
  hitsPerPage: number;
  nbHits: number;
  hits: Hit[];
};

export type Hit = _.Omit<DocMeta, 'content' | 'authorsIds'> & {
  objectID: string;
  authorsIds?: string[] | Record<string, true>;
};

export type SessionSearchResultType = Session & {
  sessionId: string;
  start_timestamp: number;
  community: string;
  kindOfPublicNames: string[];
};

export type TemplateSearchResultType = SessionTemplateDescription & {
  templateId: string;
  accessCode: string;
};

export type Users = Record<string, User>;

export type User = {
  name: string;
  email: string;
  isTemporaryUser?: boolean;
  createdAt?: number;
  expertMode?: boolean;
};

export type UserActivity = {
  lastTimeActive?: number;
};

export type SessionTemplatePrivacy = 'public' | 'private';

export type SessionTemplateDescription = {
  name: string;
  // coverUrl?: string;
  nbParticipants: [number, number];
  duration: number;
  activitiesDescription: Record<string, string>;
  // color: Color;
  visibility: SessionTemplatePrivacy;
  sourceSessionId: string;
};

export type SessionTemplateFullDescription = SessionTemplateDescription & {
  description: string;
  goals: string[];
};

export type CommunityInviteState =
  | 'pending'
  | 'sent'
  | 'failed'
  | 'accepted'
  | 'refused';

export type CommunityInvite = {
  createdAt: number;
  email: 'string';
  state: CommunityInviteState;
  inviter: WithId<User>;
};

export type SessionStatusState =
  | 'kickoff'
  | 'pre_conception'
  | 'assign_role'
  | 'not_started'
  | 'live'
  | 'finished';

export type SessionTypeType = 'digital' | 'on_site' | 'hybrid';

export type TrueSet = Record<string, true>;
export type RSet<T> = Record<string, T>;
export type TrueSetSet = RSet<TrueSet>;

export type CommunityDatabaseData = {
  sessions: Sessions;
  feedbacks: Record<string, any>;
  participantsSet: TrueSetSet;
  facilitatorsSet: TrueSetSet;
  organizersSet: TrueSetSet;
  nbDocs: number;
};

export type CommunityDashboardData = {
  id: string;
  name: string;
  nbSessions: number;
  nbParticipants: number;
  nbFacilitators: number;
  sessionsByParticipants: RSet<number>;
  facilitatorsSet: TrueSet;
  nbParticipants2MoreSessions: number;
  statisfaction: number | null;
  nbSatisfactionAnswered: number;
  wantsMore: number | null;
  nbDocs: number;
};

export type DashboardData = {
  communities: Omit<
    CommunityDashboardData,
    'facilitatorsSet' | 'participantsSet'
  >[];
  nbSessions: number;
  nbParticipants: number;
  nbFacilitators: number;
  nbParticipants2MoreSessions: number;
  statisfaction: number | null;
  nbSatisfactionAnswered: number;
  wantsMore: number | null;
  nbDocs: number;
};

export type SessionDebrief = {
  published?: boolean;
  organizersFeedback?: TrueSet;
  bestOfDocs?: TrueSetSet;
};

export type MeetingDataUserRequest = Partial<{
  requestedMuteAudio: boolean;
  requestedTurnOffVideo: boolean;
  requestedStopScreenSharing: boolean;
}>;

export type MeetingData = {
  sharingUserId?: string;
  users?: RSet<MeetingDataUserRequest>;
};

export type Credentials = {
  uid: string;
  passwordSalt?: string;
  passwordHash?: string;
};

export type PasswordResetData = {
  email: string;
  expiresAt: number;
};

export type UserAlert = {
  type: 'communityInvite';
  communityName: string;
  communityId: string;
  email: string;
  inviter: WithId<User>;
};

export type UserAlerts = Record<string, UserAlert>;

export type ActivityData = {
  productions?: Record<string, any>;
  grouping?: GroupingData;
};

export type GroupingData = {
  groupOfUser?: Record<string, string>;
  groups?: Record<string, Group>;
  status?: string;
};

export type Group = {
  number: number;
  topic?: string;
  users: Record<string, boolean>;
  assistance?: {
    askedAt: number;
    reslovedAt: number;
  };
};

export type Groups = Record<string, Group>;

export type ActivityLight = {
  name: string;
  productions?: Productions;
};

export type Productions = Record<string, Production>;

export type ProductionType =
  | {
      type: 'string';
    }
  | {
      type: 'set';
    }
  | {
      type: 'document';
      docName: string;
      docTitle: string;
      template: DocTemplate;
      visibility?: ProductionVisibility;
    }
  | {
      type: 'enum';
      options: string[];
    }
  | {
      type: 'number';
    }
  | { type: 'boolean' };

export type ProductionTypeType =
  | 'string'
  | 'enum'
  | 'set'
  | 'document'
  | 'number'
  | 'boolean';
export const PRODUCTION_TYPES = [
  'string',
  'enum',
  'set',
  'document',
  'number',
  'boolean',
];

export type Production = {
  name: string;
  description?: string;
  mode: ProductionMode;
  multiplicity?: ProductionMultiplicity;
} & ProductionType;

export type ProductionMode = 'ByUser' | 'ByGroup' | 'ByAll';
export const PRODUCTION_MODES = ['ByUser', 'ByGroup', 'ByAll'];

export type ProductionMultiplicity = 'One' | 'Each';
export const PRODUCTION_MULTIPLICITIES = ['One', 'Each'];

export type DocTemplate =
  | {
      type: 'OldTemplate';
      body: any;
    }
  | {
      type: 'document';
      docId: string;
    };
